.list-item-content {
    @extend .btn-outline;

    width: 100%;
    height: 100%;

    margin: .2rem;
    padding: .2rem .5rem;

    text-align: start;
}