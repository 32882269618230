@mixin newColor($name, $color) {
    --#{$name}: #{$color};
    --#{$name}-h: #{hue($color)};
    --#{$name}-l: #{lightness($color)};
    --#{$name}-s: #{saturation($color)};
}

@mixin newColorMod($name, $original, $hue-shift: 0deg, $saturation-shift: 0%, $lightness-shift: 0%, $alpha: 1) {
    --#{$name}-h: #{calc(var(--#{$original}-h) + $hue-shift)};
    --#{$name}-s: #{calc(var(--#{$original}-s) + $saturation-shift)};
    --#{$name}-l: #{calc(var(--#{$original}-l) + $lightness-shift)};
    --#{$name}: #{hsla(calc(var(--#{$original}-h) + $hue-shift), calc(var(--#{$original}-s) + $saturation-shift), calc(var(--#{$original}-l) + $lightness-shift), $alpha)};
}

@function mod($color, $hue-shift: 0deg, $saturation-shift: 0%, $lightness-shift: 0%, $alpha: 1){    
    $color: str-replace($color, 'var(');
    $color: str-replace($color, ')');
    $color-h: calc(var(#{$color+'-h'}) + $hue-shift);
    $color-s: calc(var(#{$color+'-s'}) + $saturation-shift);
    $color-l: calc(var(#{$color+'-l'}) + $lightness-shift);
    @return hsla($color-h, $color-s, $color-l, $alpha);
}

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
    @if $index {
      @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }
    @return $string;
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -o-transition: $args;
    transition: $args;
}

@mixin box-sizing($args...) {
    -webkit-box-sizing: $args;
    -moz-box-sizing: $args;
    -o-box-sizing: $args;
    box-sizing: $args;
}