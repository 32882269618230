.task-list {
    margin: .4rem 0;
    padding: .4rem 0;
    border-top: 1px solid var(--color-divider);
    border-bottom: 1px solid var(--color-divider);

    .task-list_add-btn {
        width: 100%;
        display: flex;
        padding: .2rem 1.25rem;
        gap: 1rem;
        align-items: center;
        justify-content: left;
    }
}

.task-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: .4rem 0;

    .task-list-item_completion {
        width: 2rem; //TODO For some reason, var(--input-size) does not result in the right size...
    }

    .task-list-item_title {
        border-bottom: 1px solid var(--color-divider);
        flex-grow: 1;
        flex-shrink: 0;
    }

    .task-list-item_select-btn {
        width: 2rem;
    }

    .drag-handle {
        height: 100%;
        // background-color: white;
        padding: 0 .5rem;
    }
}