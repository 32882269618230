#account-menu .container {
    position: fixed;
    top: 0;
    bottom: 0;
    
    width: var(--size);

    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
}