.sharing-modal {
    display: grid;
    gap: .3rem;
    width: 80vw;
    // min-width: 80rem;
    // max-width: 80vw;
    // max-height: 80vh;

    hr {
        margin: 1rem 0 .5rem 0;
        border-color: rgba(0, 0, 0, 0.2)
    }

    h4 {
        font-weight: normal;
        font-size: medium;
    }

    h6 {
        color: var(--color-bg-contrast);
    }

    .view-pane {
        display: flex;
        flex-wrap: wrap;
        gap: .4rem;
        width: 100%;
        min-height: 1rem;
        max-height: 70vh;
        max-width: 80vw;
        padding: .5rem .3rem;
        border-radius: var(--container-border-radius);
        border: 1px solid var(--color-divider);

        .permissions-editor {
            display: flex;
            width: 100%;
            justify-content: space-between;

        }

        .permissions-dropdown {
            width: min-content;
        }
    }

    .permission-block {
        display: grid;
        width: 100%;
        grid-template-columns: 2fr 1fr;
    }

    .permissions-name {
        margin: auto 1rem;
    }

    .permissions-dropdown {
        height: 3rem;
        width: 100%;
    }

    .message-box {
        color: brown;

        #{&}_header {
            height: 1rem;
            color: blue;
        }

        #{&}_input {
            width: 100%;
            height: 100%;
            color: red;
        }
    }

    label {
        margin-top: .5rem;
    }

    input {
        padding: .2rem;
        border: 1px solid var(--color-divider);
        border-radius: .2rem;

        &:disabled {
            opacity: 50%;
        }
    }

    .user-searchbar {
        padding: .2rem;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: .5rem; //1.3rem 1.3rem .5rem .5rem;
        width: 100%;
        gap: .2rem;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        input {
            flex-grow: 1;
            border: none;
            border-radius: .4rem;
            outline: none;
            font-size: medium;
            background-color: rgba(0, 0, 0, .1);
        }
    }

    .name-bubble {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: .4rem;
        padding: .3rem;
        padding-right: .4rem;

        border-radius: 50rem;
        border: 1px solid rgba(0, 0, 0, 0.2);

        &:hover {
            background-color: rgba(0, 0, 0, 0.02);
            border: 1px solid rgba(0, 0, 0, .3);
        }

        img {
            width: 1.5rem;
            border-radius: 50%;
        }

        .remove {
            cursor: pointer;
            width: 1.2rem;
            height: 1.2rem;

            >* {
                width: 100%;
                height: 100%;
            }

            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
                border-radius: 50rem;
            }
        }
    }

    .error {
        background-color: rgb(255, 0, 0, .2);

        &:hover {
            background-color: rgb(255, 0, 0, .2);
        }
    }

    .public-ctrl {
        display: grid;
        gap: .5rem;
        grid-template-columns: 2rem auto 9rem;
        grid-template-rows: auto auto;

        align-items: center;

        .public-ctrl__chkbx {}

        .public-ctrl__permissions-dropdown {
            width: 100%;
        }

        .public-ctrl__link {
            grid-column: 1/4;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: small;
            color: var(--color-bg-contrast);

            cursor: pointer;

            padding: .2rem;
            border: 1px solid var(--color-divider);
            border-radius: .2rem;
            // background-color: rgba(0, 0, 0, 0.05);
        }

        .public-ctrl__text {
            min-width: max-content;
        }

        .public-ctrl__tip {
            grid-column: 1/4;
            margin-bottom: -.3rem;
        }
    }

    // .MuiInputBase-root {
    //     height: 2rem;
    //     text-overflow: ellipsis;
    // }
}