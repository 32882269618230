//DEFAULTS
:root {
    //Accessibility
    --font-size-h1: 2rem;

    //Layout
    --header-height: 3rem;
    --footer-height: 3rem;
    --screen-margin-x: 1rem;
    --screen-margin-y: .4rem;
    --screen-margin: var(--screen-margin-y) var(--screen-margin-x);
    --item-gap: .4rem;
    --item-padding-x: .5rem;
    --item-padding-y: .2rem;
    --item-padding: var(--item-padding-y) var(--item-padding-x);


    //Color
    $color-neutral: rgb(141, 126, 104);

    @include newColor(color-primary, hsl(162, 50%, 45%));
    @include newColor(color-secondary, hsl(46, 100%, 50%));
    @include newColor(color-bg, rgb(240, 240, 240));
    @include newColor(color-bg-contrast, #282828);

    @include newColor(color-divider, rgba(0, 0, 0, .2));

    @include newColor(color-neutral, #c0c5ba);
    @include newColor(color-neutral-contrast, #424242);

    @include newColor(color-negative, #c53838);
    @include newColor(color-negative-contrast, #ffffff);

    @include newColorMod(color-positive, color-secondary);

    @include newColor(color-disabled, #aaa8);

    //Style
    --container-border-radius: .2rem;


    //Specific Overrides
    --input-size: 1.3rem;
}

@import './style-defaults';

//DARK
:root[data-theme="dark"] {
    @include newColor(color-bg, rgb(40, 40, 40));
    @include newColor(color-bg-contrast, #AAA);
    @include newColor(color-divider, #7775);
    @include newColor(color-primary, rgb(127, 72, 228));
    @include newColor(color-secondary, rgb(133, 199, 58));
}

//LIGHT
:root[data-theme="pink"] {
    @include newColor(color-bg, pink);
    @include newColor(color-bg-contrast, rgb(47, 18, 80));
    @include newColor(color-primary, rgb(82, 20, 207));
    @include newColor(color-secondary, rgb(133, 199, 58));
}

//Anti-Transition
:root[data-motion="none"] {
    * {
        -o-transition-property: none !important;
        -moz-transition-property: none !important;
        -ms-transition-property: none !important;
        -webkit-transition-property: none !important;
        transition-property: none !important;
    }
}