.new-task-form {
    display: grid;
    grid-template-columns: auto max-content;
    gap: .5rem;

    width: 100%;

    justify-content: center;
    align-items: center;

    .icon {
        grid-column: 1/2;
    }

    .input {
        grid-column: 2/3;
    }

    button.submit {
        grid-column: 1/3;
        height: 2rem;
        width: 80%;
        margin: auto;
        margin-top: .5rem;
    }
}