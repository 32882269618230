#home-view {
    display: grid;
    align-items: center;
    justify-content: center;

    grid-template-columns: 80vw;

    padding: 1rem;
    gap: 1rem;
}

.home-view_item:hover {
    background-color: mod(--color-primary, $lightness-shift: 10%, $alpha: 40%);
}

.home-view_item {
    display: flex;
    min-height: 5vh;
    padding: .4rem;
    border: 2px solid var(--color-primary);
    border-radius: 1rem;

    cursor: pointer;

    .title {
        font-size: 1.5rem;
        font-weight: bold;
        margin: auto;
        word-wrap: break-word;
    }
}

#home-view_add-btn {
    width: 80%;
    justify-self: center;
    svg {
        height: 2rem;
        width: 2rem;
    }
}