.move-node-modal {
    .modal-content {
        display: grid;
        grid-template: min-content auto 2rem / auto;
        grid-template-areas: "back-btns" "list" "search";
        gap: .5rem;

        min-height: 20vh;

        padding: .5rem;
        border: 1px solid var(--color-divider);
        border-radius: var(--container-border-radius);
    }

    .move-node-list-options {
        display: flex;
        flex-direction: column;
        gap: .4rem;
        grid-area: list;
        font-style: normal;
    }

    .node-search-bar {
        grid-area: search;
        border: 1px solid var(--color-divider);
    }
}