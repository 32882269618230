.header {
    //Positioning

    //Layout
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    //Color/Style
    background-color: mod(var(--color-bg-contrast), $alpha: .5);
    color: var(--color-bg);
    padding: var(--item-padding);

    // .title {}

    .toggle {
        background: none;
        border: none;

        height: 1.5rem;
        width: 1.5rem;

        color: var(--color-bg);

        svg {
            @include transition(transform 200ms ease-out);
        }
    }

    .toggle.open {
        svg {
            transform: rotate(-180deg);
        }
    }
}

.data-editor {
    //Layout
    display: grid;
    grid-template-columns: min-content 1fr;
    gap: var(--item-gap);

    //Color/Style
    padding: .5rem .5rem;
    border-left: 2px solid var(--color-divider);
    justify-items: flex-start;
    align-items: center;
}

.data-editor.closed {
    height: 0;
    padding: 0;
}