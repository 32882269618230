@import "Pages/NodeViewer/node-viewer";
@import "Pages/home-view";

//Layout
#root {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: "top-navbar" "content" "bottom-navbar";
    height: var(--viewable-height);
    width: 100vw;
}

#portal {
    overflow: hidden;
    // position: absolute;
    // top: 0;
}

@import './Components/top-navbar';
@import "Components/navigation-pullout";
@import './Components/account-menu-pullout';

#content {
    display: flex;
    flex-direction: column;
    grid-area: content;
    height: 100%;

    overflow-y: scroll;
    //TODO stylize or hide scrollbar
}

@import './Components/bottom-navbar';