/* The Modal (background) */
.modal-backdrop {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: mod(--color-bg-contrast, $alpha: .5);
}

/* Modal Content/Box */
.modal>div {
  background-color: var(--color-bg);
  max-height: 100vh;
  min-width: min-content;
  height: fit-content;
  width: fit-content;
  overflow: hidden;
  padding: 1rem;
  border-radius: var(--container-border-radius);
  border: 1px solid var(--color-divider);
  @include transition(max-height 1s ease-in-out);
}

.simple-modal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: .5rem;
  grid-template-areas: "title title" "info info" "cancel confirm";

  .modal-title {
    grid-area: title;
    text-align: center;
  }

  .modal-content {
    grid-area: info;
    // color: mod($color: var(--color-bg-contrast), $alpha: .5);
    // font-style: italic;
  }

  .modal-cancel,
  .modal-confirm {
    margin-top: 1rem;
  }

  .modal-cancel {
    grid-area: cancel;
    display: flex;
    align-items: center;
    gap: .5rem;
    padding: .5rem;
  }

  .modal-confirm {
    grid-area: confirm;
    display: flex;
    align-items: center;
    gap: .5rem;
    padding: .5rem;
  }
}