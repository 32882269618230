#node-search-menu {
    position: fixed;
    top: 0;
    bottom: 0;

    display: flex;
    overflow: visible;

    h1 {
        align-self: center;
        padding: 1rem;
    }
}

.node-search-header {
    //Layout
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    //Style
    z-index: 51;
    height: 4rem;
    background-color: var(--color-primary);
}


.node-search-bar {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: var(--container-border-radius);

    background-color: mod(var(--color-bg), $alpha: 50%);

    input {
        padding: 0;
        padding-left: 2rem;

        width: 100%;
        height: 100%;
    }

    svg {
        position: absolute;
        left: .5rem;
        color: mod(var(--color-bg-contrast), $alpha: 50%);
    }
}