.login-bg {
    // Position
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    // Layout
    display: flex;

    // Style
    color: white;
    background: linear-gradient(mod(--color-primary, 5deg, 20%, 15%), var(--color-primary));
}

.login-content {
    margin: auto;

    display: grid;
    grid: 1fr 3fr 1fr 1fr 2rem / 1fr;
    gap: .5rem;

    justify-content: center;
    text-align: center;

    #brand {
        font-size: 3rem;
    }

    #ready {
        font-size: 9vw;
        font-weight: 100;
        letter-spacing: 3vw;
    }

    #your-way {
        font-size: 15vw;
    }

    #disclaimer {
        opacity: 50%;
        font-style: italic;
        align-self: flex-end;
    }
}

.google-login-btn {
    margin: 0 2rem;
    box-shadow: .2rem .4rem 1rem #0005;
    border-radius: 1rem;
    border: 1px solid #fff5;
    // background-color: var(--color-positive);
    color: white;
    font-weight: bold;
}

.email-login {
    display: grid;
    padding: 3rem;
    gap: 1rem;

    input {
        height: 2rem;
        border: 1px solid var(--color-divider);
    }

    button {
        height: 3rem;
        width: 80%;
        justify-self: center;
        margin-top: 1rem;
    }
}