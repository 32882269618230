.options-container {
    display: grid;
    grid-template: 1fr / max-content auto max-content;
    align-items: center;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
    border-radius: .5rem;
    margin: 1rem;
    overflow: hidden;

    .slider {
        margin: 1rem;
        width: 90%;
    }

    .slider-label {
        margin: 1rem;
    }

    .slider-value {
        margin: 1rem;
        opacity: 0.4;
    }
}

.generate-button {
    grid-column: 1/4;
    height: 2rem;
    border-radius: 0;
}

.todolist-item {
    display: flex;
    align-self: center;

    justify-content: center;
    align-items: center;

    height: 2rem;
    width: 90%;
    margin: .5rem 1rem;
    gap: .2rem;

    .selector {
        display: flex;
        width: 100%;
        height: 100%;

        justify-content: space-between;
        align-items: center;

        padding: 0 1rem;
        font-size: 1.3rem;
    }
}