//Globals
@import "resets";
@import "themes-mixins";
@import "themes";
@import "rich-text";


//Components
@import "Components/Generic/alert";
@import "Components/Generic/pullout-menu";
@import "Components/Generic/modal";
@import "Components/Generic/reorderable-list";
@import "Components/Generic/list-item";

@import "Components/DataNodes/new-node-modal";
@import "Components/DataNodes/delete-node-modal";
@import "Components/DataNodes/move-node-modal";
@import "Components/sharing-modal";
@import "Components/DataNodes/node-search-menu";
@import "Components/subscriptions";

//Pages
@import "app";
@import "Pages/login";
@import "Pages/faq";
@import "Pages/planner";

body {
    background-color: var(--color-bg);
    color: var(--color-bg-contrast);
}