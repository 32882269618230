.pullout-menu {
    //Position
    position: absolute;

    //Layout
    display: flex;
    flex-direction: column;
    white-space: nowrap;

    //Style
    background-color: var(--color-bg);
    z-index: 50;

    .pullout-menu_backdrop {
        position: absolute;
        background-color: mod(--color-bg-contrast, $alpha: .5);
        width: 100vw;
        height: 100vh;
    }
}


/* #region  left */

.pullout-menu.left {
    //Position
    top: 0;
    bottom: 0;
    left: 0;
    width: var(--size);

    //Style
    box-shadow: .5rem 0 .5rem var(--color-divider);

    .pullout-menu_backdrop {
        left: var(--size);
        width: 100vw;
    }
}

/* #endregion */


/* #region  Right */

.pullout-menu.right {
    //Position
    top: 0;
    right: 0;
    bottom: 0;
    width: var(--size);

    //Style
    box-shadow: -.5rem 0 .5rem var(--color-divider);

    .pullout-menu_backdrop {
        right: var(--size);
    }
}

/* #endregion */

.pullout-menu.top,
.pullout-menu.bottom {
    height: var(--size);
    left: 0;
    right: 0;
}

.pullout-menu.top {
    //Position
    top: 0;

    //Style
    box-shadow: 0 .5rem .5rem var(--color-divider);

    .pullout-menu_backdrop {
        top: var(--size);
    }
}

.pullout-menu.bottom {
    //Position
    bottom: 0;

    //Style
    box-shadow: 0 -.5rem .5rem var(--color-divider);

    .pullout-menu_backdrop {
        bottom: var(--size);
    }
}

.pullout-menu.top.closed {
    top: calc(var(--size) * -1);
}

.pullout-menu.bottom.closed {
    bottom: calc(var(--size) * -1);
}

.pullout-menu.closed {
    box-shadow: none;
}