* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    font-family: sans-serif;
    background: none;

    // outline: 1px solid red;
}

body, html {
    overflow: hidden;
}

input,
textarea,
button {
    border: 1px solid transparent;
    border-radius: .2rem;
    font-size: inherit;
}

input:hover {
    border-color: var(--color-divider);
}

input:active {
    border-color: var(--color-bg-contrast);
}