#top-navbar {
    //Positioning
    grid-area: top-navbar;
    height: fit-content;
    top: 0;
    width: 100%;
    // z-index: 1;


    //Layout
    display: grid;
    grid-template: auto / auto 1fr auto;
    grid-template-areas: "menu disclaimer account";

    //Color/Style
    background-color: var(--color-primary);
    // border-bottom: 1px solid var(--color-divider);
    padding: 0 1rem;
    align-items: center;
}

.nav-button {
    //Layout
    width: 2rem;
    height: 2rem;
    margin: .5rem;

    svg {
        width: 100%;
        height: 100%;
    }
}

.menu-button {
    @extend .btn-outline;

    width: 100%;
    height: 3rem;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 1em;
    gap: .5em;
    border-radius: var(--container-border-radius);

    text-decoration: none;
    font-weight: 600;
    font-size: large;
    text-transform: uppercase;

    @extend .btn-outline;
}

#top-navbar_account-toggle {
    //Positioning
    grid-area: account;
    justify-self: end;

    border-radius: 100rem;
    overflow: hidden;
    
    outline: .15rem solid white;
}

.disclaimer {
    grid-area: disclaimer;
    font-weight: bold;
    color: var(--color-negative);
    letter-spacing: 1vw;
    font-size: 100%;
}