@import './node-context-bar';

#node-viewer {
    //Layout
    display: flex;
    flex-direction: column;
    gap: var(--item-gap);
    overflow-y: auto;

    //Colors/Style
    margin: var(--screen-margin);

    .title {
        // padding: 1rem;
        height: var(--font-size-h1);
        line-height: var(--font-size-h1);
        font-size: var(--font-size-h1);
        white-space: pre-wrap;
    }

    // TODO Currently not implemented
    .tags {
        //Layout
        display: flex;
        flex-direction: row;
        gap: var(--item-gap);
        padding: var(--item-padding-y);
        min-height: 1rem;
        margin: 0 0 1rem 0;

        //Color/Style
        color: var(--disabled);
        border: 1px dashed var(--color-divider);
        border-radius: 100rem;

        .name-bubble {
            border-radius: 50rem;
            padding: .1rem .3rem;

            background-color: mod(var(--color-bg-contrast), $alpha: .5);
            color: mod(var(--color-bg), $alpha: .5);
        }

        .name-bubble::before {
            content: "#";
        }
    }

    .data-editor-container {
        //Layout
        display: flex;
        flex-direction: column;

        margin: var(--item-margin-vertical) 0;
        border: var(--container-border);
        border-radius: var(--container-border-radius);

        @import './data-editor';
    }

    .content-editor {
        margin: .4rem 0;

        .selected {
            background-color: mod(var(--color-bg-contrast), $alpha: .1);
        }

        .header {
            display: flex;
            align-items: center;
        }

        .content {
            display: flex;
            flex-direction: column;
            width: 100%;

            margin: .5rem 0;
            padding-left: 1rem;
            border-left: 1px solid var(--color-divider);
            gap: .5rem;
            overflow: visible;

            ol {
                list-style-type: none;
                margin: 0;
                padding: 0;
                width: 100%;
            }
        }

        .list-item-container {
            display: flex;
            align-items: center;
        }

        @import '../../Components/DataNodes/Tasks/list';
    }
}

@import './content-context-bar';