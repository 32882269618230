.code-block {
    background-color: #DDD;
    padding: .5rem;
    font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
}

.quote-block::before,
.quote-block::after {
    content: "\"";
    position: absolute;
    font-size: 2rem;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    align-self: center;
    color: #0005;
}

.quote-block::before {
    position: absolute;
    left: 1.25rem;
    top: 0;
    margin-right: .5rem;

}

.quote-block::after {
    position: absolute;
    right: 1.25rem;
    top: 0;
    margin-right: .5rem;
}


.quote-block {
    background-color: #0001;
    border-left: .2rem solid #0003;
    margin: 0 .5rem;
    padding: .5rem 2rem;
}


.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}

.line-through {
    text-decoration: line-through;
}

.line-through.underline {
    text-decoration: underline line-through;
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.align-justify {
    text-align: justify;
}