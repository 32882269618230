#node-context-bar {
    //Position
    position: sticky;
    top: 0;
    width: 100%;
    height: fit-content;

    //Layout
    align-items: center;
    display: grid;
    gap: 1rem;
    padding: 0 1rem;
    grid-template-columns: 1fr auto auto;
    grid-template-areas: "nav-btns delete-btn share-btn";

    //Color/style
    background-color: var(--color-bg);
    box-shadow: var(--color-divider) 0 0 1rem;

    .context-bar-btn {
        width: 2rem;
        margin: .5rem;

        svg {
            width: 100%;
            height: 100%;
        }
    }
}

.ancestor-nav-btns {
    display: inline-block;
    overflow: scroll;
    white-space: nowrap;
    width: max-content;
    padding: 0 .4rem;
    
    button {
        padding: .2rem .4rem;
        width: fit-content;
    }
}

.ancestor-nav-btns::-webkit-scrollbar {
    display: none;
}