.FAQ {
    h1 {
        padding: 1rem;
        text-align: center;
        background-color: var(--color-bg);
        color: var(--color-bg-contrast);
    }

    h2 {
        text-align: center;
        cursor: pointer;
        padding: 1rem;
        color: var(--color-bg-contrast);
        font-size: large;
        background-color: var(--color-primary_1);
        border-top: 2px solid #00000044;
    }


    .qa-box {
        background-color: var(--color-primary_2);
    }

    .qa-alt-toggle {
        p {
            color: rgba(var(--color-bg-contrast), .7);
            font-size: medium;
            font-weight: 500;
            padding: 1rem;
            width: 80%;

            a {
                text-decoration: none;
            }
        }

        cursor: help;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .paypal-link {
        display: flex;
        margin-bottom: .5rem;
        justify-content: center;
    }
}