#content-context-bar {
    height: fit-content;
    margin-top:auto;
    width: 100%;
    box-shadow: var(--color-divider) 0 0 1rem;
    background-color: var(--color-bg);
    padding: 0 .5rem;

    display: flex;
    align-items: center;
    overflow: hidden;

    .add-data-pullout {
        @include transition(width 200ms ease);
        display: inline-flex;
        align-items: center;
        justify-content: left;

        .add-btn {
            display: flex;
            width: 2rem;
            align-items: center;
            justify-content: center;
        }

        .search-input {
            @include transition(width 200ms ease);
            width: 0;
            padding: 0;
            transform: translate(-10rem);
            border: none;
        }
    }

    .add-data-pullout.open {
        width: 100%;

        .search-input {
            width: 100vw;
            padding-left: 0.2rem;
            border: inherit;
            transform: none;
        }
    }

    .formatting-buttons-container {
        display: flex;
        padding: .2rem;
        width: 100%;
        justify-content: space-around;
    }
}

.format-btn {
    display: flex;
    min-width: 3rem;
    width: 100%;
    max-width: 6rem;
    outline: 1px solid mod(var(--color-bg), $alpha: .5);

}

.format-btn.active,
.format-btn:hover,
.format-btn:focus {
    // color: var(--color-bg-contrast);
    outline: none;
    // background-color: var(--color-bg-contrast-faded);
}