.node-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
}

.node-preview {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    outline: 1px solid var(--color-divider);
    padding: .5rem .5rem .5rem 1rem;
    border-radius: var(--container-border-radius);
    cursor: pointer;

    span {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}