#bottom-navbar {
    // TODO this should be handled in js/react
    max-height: calc(100vh * (1 - var(--soft-keyboard)));

    //Positioning
    height: fit-content;
    width: 100%;
    z-index: 1;

    //Layout
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: .1rem;

    //Color
    background-color: mod(var(--color-primary), $lightness-shift: -10%);
    // border-top: 1px solid var(--color-divider);

    button {
        height: 4rem;
        border-radius: 0;

        svg {
            width: 2rem;
            height: 2rem;
        }
    }
}