input[type='checkbox'] {
    accent-color: mod($color:var(--color-primary), $lightness-shift: -10%);
    width: var(--input-size);
}

// input[type='datetime-local'] {
//     padding: .1rem;
// }

input {
    height: var(--input-size);
    color: var(--color-bg-contrast);
    padding: .2rem .5rem;
    @include box-sizing(content-box);
}


/* #region  BUTTONS */

.btn,
button {
    color: var(--color-bg);
    align-items: center;
    justify-content: center;

    img,
    svg {
        // fill: var(--color-bg);
        width: 100%;
        height: 100%;
    }

    background-color: var(--color-primary);
    cursor: pointer;
}

.btn:hover,
button:hover {
    background-color: var(--color-bg);
    color: var(--color-primary);

    // svg {
    //     fill: var(--color-primary);
    // }
}

.btn-outline {
    background: var(--color-bg);
    border: 1px solid var(--color-primary);
    color: var(--color-primary);

    // svg {
    //     fill: var(--color-primary);
    // }
}

.btn-outline:hover {
    background: var(--color-primary);
    color: var(--color-bg);

    // svg {
    //     fill: var(--color-bg);
    // }
}

.btn-text {
    background: var(--color-bg);
    color: var(--color-primary);
}

.btn-text:hover {
    background: var(--color-primary);
    color: var(--color-bg);

    // svg {
    //     fill: var(--color-bg);
    // }
}

.btn-delete {
    background-color: var(--color-negative);
    color: var(--color-negative-contrast);
}

.btn-cancel {
    background-color: var(--color-neutral);
    color: var(--color-neutral-contrast);
}

/* #endregion */


/* #region  HR CLASS */

.hr::before,
.hr::after {
    background-color: var(--color-divider);
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}

.hr::before {
    right: 0.5em;
    margin-left: -50%;
}

.hr::after {
    left: 0.5em;
    margin-right: -50%;
}

.hr {
    color: var(--color-divider);
    overflow: hidden;
    text-align: center;
}

/* #endregion */