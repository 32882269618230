.alert-backdrop {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.4);
}

.alert {
    background-color: #fefefe;
    height: min-content;
    width: min-content;
    overflow: hidden;
    padding: 1rem;
    border-radius: --container-border-radius;
}

.message {}

.button {}

.no-button {}

.yes-button {}